<template>
  <v-row justify="center" no-gutters>
    <v-expansion-panels accordion multiple tile>
      <v-expansion-panel>
        <v-expansion-panel-header>{{
            $vuetify.lang.t('$vuetify.general.filter.sortAndOrder')
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group v-model="localItem.sortedBy" :label="$vuetify.lang.t('$vuetify.general.filter.sortBy')">
            <v-radio label="A-Z" value="desc"/>
            <v-radio label="Z-A" value="asc"/>
          </v-radio-group>
          <v-select v-model="localItem.orderBy" :items="inspectionFields"
                    :label="$vuetify.lang.t('$vuetify.general.filter.orderBy')" hide-details outlined persistent-hint/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="isInspectionsPage || isGroupPage">
        <v-expansion-panel-header>{{
            $vuetify.lang.t('$vuetify.general.filter.inspectionAssignmentStatus')
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox v-model="inspectorAssigned"
                      :label="$vuetify.lang.t('$vuetify.general.filter.assignedToInspector')"
                      hide-details/>
          <v-checkbox v-model="inspectorUnassigned"
                      :label="$vuetify.lang.t('$vuetify.general.filter.notAssignedToInspector')"
                      hide-details/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>{{
            $vuetify.lang.t('$vuetify.general.filter.categoriesOfSites')
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kurcc-autocomplete :items="categories" :loading="fetchingCategories"
                              :name="$vuetify.lang.t('$vuetify.general.filter.categories')"
                              :selected-items.sync="localItem.categories" multiple outlined rules=""/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="isInspectionsPage">
        <v-expansion-panel-header>{{
            $vuetify.lang.t('$vuetify.general.filter.inspectorsGroups')
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <kurcc-autocomplete :items="groups" :loading="fetchingGroups"
                              :name="$vuetify.lang.t('$vuetify.general.filter.groups')"
                              :selected-items.sync="localItem.groups" multiple outlined rules=""/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="isInspectionsPage">
        <v-expansion-panel-header>{{
            $vuetify.lang.t('$vuetify.general.filter.groupAssignmentStatus')
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox v-model="groupAssigned" :label="$vuetify.lang.t('$vuetify.general.filter.assignedToGroup')"
                      hide-details/>
          <v-checkbox v-model="groupUnassigned" :label="$vuetify.lang.t('$vuetify.general.filter.notAssignedToGroup')"
                      hide-details/>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="isInspectionsPage || isGroupPage">
        <v-expansion-panel-header>{{
            $vuetify.lang.t('$vuetify.general.filter.inspectionType')
          }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox v-model="localItem.inspectionType.routine"
                      :label="$vuetify.lang.t('$vuetify.general.filter.routine')"
                      hide-details/>
          <v-checkbox v-model="localItem.inspectionType.custom"
                      :label="$vuetify.lang.t('$vuetify.general.filter.custom')"
                      hide-details/>
          <v-checkbox v-model="localItem.inspectionType.revisit"
                      :label="$vuetify.lang.t('$vuetify.general.filter.revisit')"
                      hide-details/>
        </v-expansion-panel-content>
      </v-expansion-panel>

    </v-expansion-panels>
  </v-row>
</template>

<script>
export default {
  name: 'KurccFilterFilters',
  components: {
    KurccAutocomplete: () => import('@/modules/app/components/KurccAutocomplete')
  },
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      inspectorAssigned: true,
      inspectorUnassigned: true,
      groupAssigned: true,
      groupUnassigned: true,
      fetchingCategories: false,
      categories: [],
      fetchingGroups: false,
      groups: [],
      inspectionFields: [
        'deadline_days',
        'score',
        'grade',
        'type',
        'created_at'
      ]
    }
  },
  watch: {
    inspectorAssigned: 'computeInspectorAssignmentStatus',
    inspectorUnassigned: 'computeInspectorAssignmentStatus',
    groupAssigned: 'computeGroupAssignmentStatus',
    groupUnassigned: 'computeGroupAssignmentStatus'
  },
  computed: {
    localItem: {
      get () {
        return this.filter
      },
      set (v) {
        this.$emit('update:filter', v)
      }
    },
    isInspectionsPage () {
      return this.$route.name === 'inspections'
    },
    isGroupPage () {
      return this.$route.name === 'group'
    }
  },
  methods: {
    computeInspectorAssignmentStatus () {
      if ((this.inspectorAssigned && this.inspectorUnassigned) || (!this.inspectorAssigned && !this.inspectorUnassigned)) {
        this.localItem.assignmentStatus = null
      } else {
        if (this.inspectorAssigned) {
          this.localItem.assignmentStatus = true
        }
        if (this.inspectorUnassigned) {
          this.localItem.assignmentStatus = false
        }
      }
    },
    computeGroupAssignmentStatus () {
      if ((this.groupAssigned && this.groupUnassigned) || (!this.groupAssigned && !this.groupUnassigned)) {
        this.localItem.groupAssignmentStatus = null
      } else {
        if (this.groupAssigned) {
          this.localItem.groupAssignmentStatus = true
        }
        if (this.groupUnassigned) {
          this.localItem.groupAssignmentStatus = false
        }
      }
    },
    getAllCategories () {
      this.fetchingCategories = true
      this.$store.dispatch('getAllCategories', { withIncludes: false })
        .then(res => {
          this.categories = res.items
        })
        .finally(() => {
          this.fetchingCategories = false
        })
    },
    getAllGroups () {
      this.fetchingGroups = true
      this.$store.dispatch('getAllGroups', { withIncludes: false })
        .then(res => {
          this.groups = res.items
        })
        .finally(() => {
          this.fetchingGroups = false
        })
    }
  },
  created () {
    this.getAllCategories()
    this.getAllGroups()
  }
}
</script>
